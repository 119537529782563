import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const getMemberContent = createAsyncThunk(
  "/member/content",
  async () => {
    const response = await axios.get("/members", {});
    return response.data;
  }
);

export const memberSlice = createSlice({
  name: "member",
  initialState: {
    isLoading: false,
    member: [],
  },
  reducers: {
    addNewMember: (state, action) => {
      let { newMemberObj } = action.payload;
      state.member = [...state.member, newMemberObj];
    },
    editMember: (state, action) => {
      let { newMemberObj, id } = action.payload;
      state.member = [
        ...state.member.filter((item) => item.username !== id),
        newMemberObj,
      ];
    },
    deleteMember: (state, action) => {
      let { index } = action.payload;
      state.member.splice(index, 1);
    },
  },
  extraReducers: {
    [getMemberContent.pending]: (state) => {
      state.isLoading = true;
    },
    [getMemberContent.fulfilled]: (state, action) => {
      state.member = action.payload;
      state.isLoading = false;
    },
    [getMemberContent.rejected]: (state) => {
      state.isLoading = false;
    },
  },
});

export const { addNewMember, deleteMember, editMember } = memberSlice.actions;

export default memberSlice.reducer;
