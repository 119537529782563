import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const getSessionContent = createAsyncThunk(
  "/session/content",
  async () => {
    const response = await axios.get("/session", {});
    return response.data;
  }
);

export const sessionSlice = createSlice({
  name: "session",
  initialState: {
    isLoading: false,
    session: [],
  },
  reducers: {
    addNewSession: (state, action) => {
      let { newSessionObj } = action.payload;
      state.session = [...state.session, newSessionObj];
    },
    editSession: (state, action) => {
      let { newSessionObj, id } = action.payload;
      state.session = [
        ...state.session.filter((item) => item.id_session !== id),
        newSessionObj,
      ];
    },
    deleteSession: (state, action) => {
      let { index } = action.payload;
      state.session.splice(index, 1);
    },
  },
  extraReducers: {
    [getSessionContent.pending]: (state) => {
      state.isLoading = true;
    },
    [getSessionContent.fulfilled]: (state, action) => {
      state.session = action.payload;
      state.isLoading = false;
    },
    [getSessionContent.rejected]: (state) => {
      state.isLoading = false;
    },
  },
});

export const { addNewSession, deleteSession, editSession } =
  sessionSlice.actions;

export default sessionSlice.reducer;
