import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const getSchedulesContent = createAsyncThunk(
  "/schedules/content",
  async () => {
    const response = await axios.get("/schedules", {});
    return response.data;
  }
);

export const schedulesSlice = createSlice({
  name: "schedules",
  initialState: {
    isLoading: false,
    schedules: [],
  },
  reducers: {
    addNewSchedules: (state, action) => {
      let { newSchedulesObj } = action.payload;
      state.schedules = [...state.schedules, newSchedulesObj];
    },
    editSchedules: (state, action) => {
      let { newSchedulesObj, id } = action.payload;
      state.schedules = [
        ...state.schedules.filter((item) => item.id_schedule !== id),
        newSchedulesObj,
      ];
    },
    deleteSchedules: (state, action) => {
      let { id } = action.payload;
      state.schedules = state.schedules.filter(
        (item) => item.id_schedule !== id
      );
    },
  },
  extraReducers: {
    [getSchedulesContent.pending]: (state) => {
      state.isLoading = true;
    },
    [getSchedulesContent.fulfilled]: (state, action) => {
      state.schedules = action.payload;
      state.isLoading = false;
    },
    [getSchedulesContent.rejected]: (state) => {
      state.isLoading = false;
    },
  },
});

export const { addNewSchedules, deleteSchedules, editSchedules } =
  schedulesSlice.actions;

export default schedulesSlice.reducer;
