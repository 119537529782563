import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const getProductsContent = createAsyncThunk(
  "/products/content",
  async () => {
    const response = await axios.get("/products", {});
    return response.data;
  }
);

export const productsSlice = createSlice({
  name: "products",
  initialState: {
    isLoading: false,
    products: [],
  },
  reducers: {
    addNewProduct: (state, action) => {
      let { newProductObj } = action.payload;
      state.products = [...state.products, newProductObj];
    },
    editProduct: (state, action) => {
      let { newProductObj, id } = action.payload;
      state.products = [
        ...state.products.filter((item) => item.id_product !== id),
        newProductObj,
      ];
    },
    deleteProduct: (state, action) => {
      let { index } = action.payload;
      state.products.splice(index, 1);
    },
  },
  extraReducers: {
    [getProductsContent.pending]: (state) => {
      state.isLoading = true;
    },
    [getProductsContent.fulfilled]: (state, action) => {
      state.products = action.payload;
      state.isLoading = false;
    },
    [getProductsContent.rejected]: (state) => {
      state.isLoading = false;
    },
  },
});

export const { addNewProduct, deleteProduct, editProduct } =
  productsSlice.actions;

export default productsSlice.reducer;
