import axios from "axios";

const initializeApp = () => {
  // Setting base URL for all API requests via axios
  axios.defaults.baseURL =
    process.env.REACT_APP_ENV === "production"
      ? process.env.REACT_APP_BASE_URL_PROD_API
      : process.env.REACT_APP_BASE_URL_SANDBOX_API;

  // Setting default headers (e.g., Content-Type)
  axios.defaults.headers.common["Content-Type"] = "application/json";

  if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
    // Dev code
  } else {
    // Prod build code

    // Removing console.log from prod
    console.log = () => {};
    // Initialize analytics here
  }
};

// Example of a basic GET request
const fetchData = async (endpoint) => {
  try {
    const response = await axios.get(endpoint);
    console.log("Data fetched successfully:", response.data);
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    return error.response;
  }
};

// Example of a basic POST request
const postData = async (endpoint, data) => {
  try {
    const response = await axios.post(endpoint, data);
    console.log("Data posted successfully:", response.data);
    return response.data;
  } catch (error) {
    console.error("Error posting data:", error);
    return error.response;
  }
};

const putData = async (endpoint, data, id) => {
  try {
    const response = await axios.put(`${endpoint}/${id}`, data);
    console.log("Data updated successfully:", response.data);
    return response.data;
  } catch (error) {
    console.error("Error posting data:", error);
    return error.response;
  }
};

const deleteData = async (endpoint, id) => {
  try {
    const response = await axios.delete(`${endpoint}/${id}`);
    console.log("Data deleted successfully:", response.data);
    return response.data;
  } catch (error) {
    console.error("Error posting data:", error);
    return error.response;
  }
};

// Example of setting a default authorization header
const setAuthorizationToken = (token) => {
  if (token) {
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  } else {
    delete axios.defaults.headers.common["Authorization"];
  }
};

export {
  initializeApp,
  fetchData,
  postData,
  setAuthorizationToken,
  putData,
  deleteData,
};
