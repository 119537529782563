import { configureStore } from "@reduxjs/toolkit";
import headerSlice from "../features/common/headerSlice";
import modalSlice from "../features/common/modalSlice";
import rightDrawerSlice from "../features/common/rightDrawerSlice";
import leadsSlice from "../features/leads/leadSlice";
import productsSlice from "../features/products/productsSlice";
import packagesSlice from "../features/packages/packagesSlice";
import sessionSlice from "../features/session/sessionSlice";
import memberSlice from "../features/member/memberSlice";
import subscriptionSlice from "../features/subscription/subscriptionSlice";
import schedulesSlice from "../features/calendar/schedulesSlice";
import userSlice from "../features/user/userSlice";

const combinedReducer = {
  header: headerSlice,
  rightDrawer: rightDrawerSlice,
  modal: modalSlice,
  lead: leadsSlice,
  products: productsSlice,
  packages: packagesSlice,
  session: sessionSlice,
  member: memberSlice,
  subscription: subscriptionSlice,
  schedules: schedulesSlice,
  user: userSlice,
};

export default configureStore({
  reducer: combinedReducer,
});
