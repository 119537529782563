import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const getPackagesContent = createAsyncThunk(
  "/packages/content",
  async () => {
    const response = await axios.get("/packages", {});
    return response.data;
  }
);

export const packagesSlice = createSlice({
  name: "packages",
  initialState: {
    isLoading: false,
    packages: [],
  },
  reducers: {
    addNewPackages: (state, action) => {
      let { newPackagesObj } = action.payload;
      state.packages = [...state.packages, newPackagesObj];
    },
    editPackages: (state, action) => {
      let { newPackagesObj, id } = action.payload;
      state.packages = [
        ...state.packages.filter((item) => item.id_packages !== id),
        newPackagesObj,
      ];
    },
    deletePackages: (state, action) => {
      let { index } = action.payload;
      state.packages.splice(index, 1);
    },
  },
  extraReducers: {
    [getPackagesContent.pending]: (state) => {
      state.isLoading = true;
    },
    [getPackagesContent.fulfilled]: (state, action) => {
      state.packages = action.payload;
      state.isLoading = false;
    },
    [getPackagesContent.rejected]: (state) => {
      state.isLoading = false;
    },
  },
});

export const { addNewPackages, deletePackages, editPackages } =
  packagesSlice.actions;

export default packagesSlice.reducer;
