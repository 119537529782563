import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const getSubscriptionContent = createAsyncThunk(
  "/subscription/content",
  async () => {
    const response = await axios.get("/subscriptions", {});
    return response?.data?.data;
  }
);

export const subscriptionSlice = createSlice({
  name: "subscription",
  initialState: {
    isLoading: false,
    subscription: [],
  },
  reducers: {
    addNewSubscription: (state, action) => {
      let { newSubscriptionObj } = action.payload;
      state.subscription = [...state.subscription, newSubscriptionObj];
    },
    editSubscription: (state, action) => {
      let { newSubscriptionObj, id } = action.payload;
      state.subscription = [
        ...state.subscription.filter((item) => item.id_subscription !== id),
        newSubscriptionObj,
      ];
    },
    deleteSubscription: (state, action) => {
      let { index } = action.payload;
      state.subscription.splice(index, 1);
    },
  },
  extraReducers: {
    [getSubscriptionContent.pending]: (state) => {
      state.isLoading = true;
    },
    [getSubscriptionContent.fulfilled]: (state, action) => {
      state.subscription = action.payload;
      state.isLoading = false;
    },
    [getSubscriptionContent.rejected]: (state) => {
      state.isLoading = false;
    },
  },
});

export const { addNewSubscription, deleteSubscription, editSubscription } =
  subscriptionSlice.actions;

export default subscriptionSlice.reducer;
